import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { time12FormateTraining } from '@/Utils/fliter'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
          columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]

        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['19%', '1%', '37%', '23%', '1%', '19%'],
              body: [
                [
                  { text: vm.$t('elearning_config.organization'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.getOrgData(data.org_id), style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.getFiscalYear(data.fiscal_year_id), style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.getTrainingType(data.training_type_id), style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_tim.evaluation_type'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.examTypeName(data.exam_type), style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('elearning_config.training_category'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.getTrainingCategory(data.training_category_id), style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_tim.evaluation_date'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$options.filters.dateFormat(data.exam_date), style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.getTrainingTitle(data.training_title_id), style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_tim.evaluation_start_time'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: time12FormateTraining(data.exam_time_start), style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: data.circular_memo_no, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_tim.evaluation_end_time'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: time12FormateTraining(data.exam_time_end), style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('elearning_iabm.batch_no'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.getBatchNo(data.batch_no), style: 'td', alignment: 'left' },
                  { },
                  { },
                  { }
                ]
              ]
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        if (data.course_evaluation_answers.length) {
          let allRows = []
          allRows = [
            [
              { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
              { text: vm.$t('elearning_tim.question'), style: 'th', alignment: 'center' },
              { text: vm.$t('elearning_tim.marks'), style: 'th', alignment: 'center' },
              { text: vm.$t('elearning_tim.obtained_marks'), style: 'th', alignment: 'center' }
            ]
          ]
          // table body
          data.course_evaluation_answers.filter((answer, index) => {
            const rowItem = [
              { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
              { text: vm.currentLocale === 'en' ? answer.evaluation_question.question : answer.evaluation_question.question_bn + ' (' + vm.getQuestionType(answer.evaluation_question.question_type) + ' )', style: 'td', alignment: 'center' },
              { text: vm.$n(answer.evaluation_question.marks), style: 'td', alignment: 'center' },
              { text: vm.$n(answer.marks), style: 'td', alignment: 'center' }
            ]
            allRows.push(rowItem)
          })
          const tableFooterRow = [
            { text: vm.$t('globalTrans.total'), style: 'td', alignment: 'right', colSpan: 2 },
            { },
            { text: vm.$n(vm.totalMarks), style: 'td', alignment: 'center' },
            { text: vm.$n(vm.totalObtainedMarks), style: 'td', alignment: 'center' }
          ]
          allRows.push(tableFooterRow)
          pdfContent.push({ text: '', style: 'tableMargin' })
          pdfContent.push({
            table: {
              style: 'tableMargin',
              headerRows: 1,
              widths: ['10%', '66%', '12%', '12%'],
              body: allRows
            }
          })
        }
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            bold: true,
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          fertilizer: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
          },
          headerPort1: {
            bold: true,
            fontSize: (i18n === 'bn') ? 13 : 12,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          tableMargin: {
            margin: [0, 15, 0, 0]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('online-course-evaluation')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
