<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('elearning_tim.pre_post_evaluation_result')}}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="6" sm="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="exam_date"
                >
                  <template v-slot:label>
                    {{ $t('elearning_tim.exam_date') }}
                  </template>
                  <b-form-input
                      class="fromDate"
                      id="exam_date"
                      v-model="search.exam_date"
                      :placeholder="$t('globalTrans.select_date')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('elearning_config.organization')"
                    label-for="organization"
                >
                  <v-select name="org_id"
                    v-model="search.org_id"
                    label="text"
                    :reduce="item => item.value"
                    :options="orgList"
                    disabled
                    :placeholder="$t('globalTrans.select')"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="fiscal_year_id"
                >
                  <template v-slot:label>
                    {{$t('elearning_config.fiscal_year')}}
                  </template>
                  <v-select name="fiscal_year_id"
                    v-model="search.fiscal_year_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= fiscalYearList
                    :placeholder="$t('globalTrans.select')"
                  />
                </b-form-group>
              </b-col>
                <b-col lg="6" sm="6" class="text-right">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_tim.pre_post_evaluation_result')}} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                      <template v-slot:cell(index)="data">
                                      {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <template v-slot:cell(training_title)="data">
                                        <span class="capitalize">{{ data.item.training_title }}</span>
                                      </template>
                                      <template v-slot:cell(exam_date)="data">
                                        <span class="capitalize">{{ data.item.exam_date | dateFormat }}</span>
                                      </template>
                                      <template v-slot:cell(exam_type)="data">
                                        <span class="">{{ examTypeData(data.item.exam_type) }}</span>
                                      </template>
                                      <template v-slot:cell(exam_time_start)="data">
                                        <span class="capitalize">{{ data.item.exam_time_start | time12FormateTraining }}</span>
                                      </template>
                                      <template v-slot:cell(exam_time_end)="data">
                                        <span class="capitalize">{{ data.item.exam_time_end | time12FormateTraining }}</span>
                                      </template>
                                      <template v-slot:cell(status)="data">
                                        <span class="badge badge-warning" v-if="data.item.status == 1">{{$t('globalTrans.pending')}}</span>
                                        <span class="badge badge-success" v-else>{{$t('globalTrans.approved')}}</span>
                                      </template>
                                      <template v-slot:cell(action)="data">
                                        <a href="javascript:" class="btn_table_action table_action_view mr-1" v-b-modal.modal-4 @click="edit(data.item)">
                                          <i class="fas fa-eye"></i>
                                        </a>
                                        <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-warning mr-1 mb-1" size="sm" @click="edit(data.item)">
                                          <i class="fas fa-eye"></i>
                                        </b-button> -->
                                      </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="$t('elearning_tim.pre_post_evaluation_result')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <template #modal-title class="flex-grow-1">
            {{ $t('elearning_tim.pre_post_evaluation_result') }}
            <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
          </template>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { courseEvaluationList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'
import Details from './Details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
      return {
        search: {
          exam_date: '',
          org_id: this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg,
          fiscal_year_id: [],
          personalInfoList: []
        }
      }
    },
    computed: {
      examTypeList: function () {
        return [
          { text: this.currentLocale === 'en' ? 'Pre-Evaluation' : 'প্রি-ইভালুয়েশন', value: 1, text_en: 'Pre-Evaluation', text_bn: 'প্রি-ইভালুয়েশন' },
          { text: this.currentLocale === 'en' ? 'Post-Evaluation' : 'পোস্ট-ইভালুয়েশন', value: 2, text_en: 'Post-Evaluation', text_bn: 'পোস্ট-ইভালুয়েশন' }
        ]
      },
      orgList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      fiscalYearList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      trainingTitleList: function () {
        return this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('elearning_config.organization'), class: 'text-left' },
          { label: this.$t('elearning_tim.evaluation_type'), class: 'text-center' },
          { label: this.$t('elearning_tim.exam_date'), class: 'text-center' },
          { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-center' },
          { label: this.$t('elearning_config.training_title'), class: 'text-center' },
          { label: this.$t('elearning_tim.evaluation_start_time'), class: 'text-center' },
          { label: this.$t('elearning_tim.evaluation_end_time'), class: 'text-center' },
          // { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.result'), class: 'text-center' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
          keys = [
          { key: 'index' },
          { key: 'org_bn' },
          { key: 'exam_type' },
          { key: 'exam_date' },
          { key: 'circular_memo_no' },
          { key: 'training_title_bn' },
          { key: 'exam_time_start' },
          { key: 'exam_time_end' },
          // { key: 'status' },
          { key: 'action' }
          ]
        } else {
          keys = [
          { key: 'index' },
          { key: 'org' },
          { key: 'exam_type' },
          { key: 'exam_date' },
          { key: 'circular_memo_no' },
          { key: 'training_title' },
          { key: 'exam_time_start' },
          { key: 'exam_time_end' },
          // { key: 'status' },
          { key: 'action' }
          ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
      }
    },
    watch: {
    },
    async created () {
      this.loadData()
    },
    mounted () {
      core.index()
      flatpickr('.fromDate', {})
    },
    methods: {
      async searchData () {
        this.loadData()
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, courseEvaluationList, params).then(response => {
            if (response.success) {
              this.personalInfoList = response.personalInfoList
                this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                this.paginationData(response.data)
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const customItem = {}
          const orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          if (typeof orgObj !== 'undefined') {
            customItem.org = orgObj.text_en
            customItem.org_bn = orgObj.text_bn
          } else {
            customItem.org = ''
            customItem.org_bn = ''
          }
          const personalInfoObj = this.personalInfoList.find(doc => doc.value === parseInt(item.personal_info_id))
          if (typeof personalInfoObj !== 'undefined') {
            customItem.trainee_name = personalInfoObj.text_en
            customItem.trainee_name_bn = personalInfoObj.text_bn
          } else {
            customItem.trainee_name = ''
            customItem.trainee_name_bn = ''
          }
          const trainingTypeObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          if (typeof trainingTypeObj !== 'undefined') {
            customItem.training_type = trainingTypeObj.text_en
            customItem.training_type_bn = trainingTypeObj.text_bn
          } else {
            customItem.training_type = ''
            customItem.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          if (typeof trainingCategoryObj !== 'undefined') {
            customItem.training_category = trainingCategoryObj.text_en
            customItem.training_category_bn = trainingCategoryObj.text_bn
          } else {
            customItem.training_category = ''
            customItem.training_category_bn = ''
          }
          const trainingTitleObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          if (typeof trainingTitleObj !== 'undefined') {
            customItem.training_title = trainingTitleObj.text_en
            customItem.training_title_bn = trainingTitleObj.text_bn
          } else {
            customItem.training_title = ''
            customItem.training_title_bn = ''
          }
          const courseObj = this.$store.state.ExternalUserTraining.traineeTranerObj.courseList.find(doc => doc.value === parseInt(item.course_id))
          if (typeof courseObj !== 'undefined') {
            customItem.course_name = courseObj.text_en
            customItem.course_name_bn = courseObj.text_bn
          } else {
            customItem.course_name = ''
            customItem.course_name_bn = ''
          }

          return Object.assign({}, item, customItem)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      },
      examTypeData (id) {
        const batchData = this.examTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return batchData !== undefined ? batchData.text_bn : ''
        } else {
            return batchData !== undefined ? batchData.text_en : ''
        }
      }
    }
}
</script>

<style>
.modal-title {
  flex-grow: 1 !important;
}
</style>
