<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-row class="text-black mb-4">
                  <b-col lg="8">
                    <span><strong>{{ $t('elearning_config.organization') }}:</strong> {{ getOrgData(formData.org_id) }}</span><br>
                    <span><strong>{{ $t('elearning_config.training_type') }}:</strong> {{ getTrainingType(formData.training_type_id) }}</span><br>
                    <span><strong>{{ $t('elearning_config.training_category') }}:</strong> {{ getTrainingCategory(formData.training_category_id) }}</span><br>
                    <span><strong>{{ $t('elearning_config.training_title') }}:</strong> {{ getTrainingTitle(formData.training_title_id) }}</span><br>
                    <span><strong>{{ $t('elearning_iabm.circular_memo_no') }}:</strong> {{ formData.circular_memo_no }}</span><br>
                    <span><strong>{{ $t('elearning_iabm.batch_no') }}:</strong> {{ getBatchNo(formData.batch_no) }}</span><br>
                  </b-col>
                  <b-col lg="4" offset="">
                    <span><strong>{{ $t('elearning_config.fiscal_year') }}:</strong> {{ getFiscalYear(formData.fiscal_year_id) }}</span><br>
                    <span><strong>{{ $t('elearning_tim.evaluation_type') }}:</strong> {{ examTypeName(formData.exam_type) }}</span><br>
                    <span><strong>{{ $t('elearning_tim.evaluation_date') }}:</strong> {{ formData.exam_date | dateFormat }}</span><br>
                    <span><strong>{{ $t('elearning_tim.evaluation_start_time') }}:</strong> {{ formData.exam_time_start | time12FormateTraining }}</span><br>
                    <span><strong>{{ $t('elearning_tim.evaluation_end_time') }}:</strong> {{ formData.exam_time_end | time12FormateTraining }}</span><br>
                  </b-col>
                </b-row>

                <div class="question-area">
                  <b-table-simple class="tg mt-3" hover small caption-top>
                    <thead>
                    <tr>
                      <th>{{ $t('globalTrans.sl_no') }}</th>
                      <th>{{ $t('elearning_tim.question') }}</th>
                      <th class="text-right">{{ $t('elearning_tim.marks') }}</th>
                      <th class="text-right">{{ $t('elearning_tim.obtained_marks') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(answer, index) in formData.course_evaluation_answers" :key="index">
                      <td>{{ $n(index + 1) }}</td>
                      <td>{{ currentLocale === 'en' ? answer.evaluation_question.question : answer.evaluation_question.question_bn }} {{ '(' + getQuestionType(answer.evaluation_question.question_type) + ')' }}</td>
                      <td class="text-right">{{ $n(answer.evaluation_question.marks) }}</td>
                      <td class="text-right">{{ $n(answer.marks) }}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                      <th class="text-right" colspan="2">{{ $t('globalTrans.total') }}</th>
                      <th class="text-right">{{ $n(totalMarks) }}</th>
                      <th class="text-right">{{ $n(totalObtainedMarks) }}</th>
                    </tr>
                    </tfoot>
                  </b-table-simple>
                </div>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      <!-- <pre>{{formData}}</pre> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { trainingElearningServiceBaseUrl, seedFertilizerServiceBaseUrl } from '@/config/api_config'
import ExportPdf from './export_pdf_details'

export default {
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.getTotalMarks()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      formData: {},
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      totalMarks: 0,
      totalObtainedMarks: 0
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.filter(item => item.status === 1)
    },
    trainingCategoryList: function () {
      return this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.filter(item => item.status === 1)
    },
    trainingTitleList: function () {
      return this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.filter(item => item.status === 1)
    },
    examTypeList: function () {
      return [
        { text: this.currentLocale === 'en' ? 'Pre-Evaluation' : 'প্রি-ইভালুয়েশন', value: 1, text_en: 'Pre-Evaluation', text_bn: 'প্রি-ইভালুয়েশন' },
        { text: this.currentLocale === 'en' ? 'Post-Evaluation' : 'পোস্ট-ইভালুয়েশন', value: 2, text_en: 'Post-Evaluation', text_bn: 'পোস্ট-ইভালুয়েশন' }
      ]
    },
    allBatchList () {
      const batchList = [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' }
      ]
      return batchList
    },
    currentLocale: function () {
      return this.$i18n.locale
    },
    questionTypeList: function () {
      return [
        { text: this.currentLocale === 'en' ? 'MCQ' : 'এমসিকিউ', value: 1 },
        { text: this.currentLocale === 'en' ? 'True Or False' : 'সত্য অথবা মিথ্যা', value: 2 },
        { text: this.currentLocale === 'en' ? 'Fill in the Blanks' : 'শূন্যস্থান পূরণ', value: 3 }
      ]
    }
  },
  methods: {
    getQuestionType (questionType) {
      return this.questionTypeList.find(item => item.value === questionType).text
    },
    getTrainingTitle (id) {
      const examType = this.trainingTitleList.find(item => item.value === parseInt(id))
      if (this.$i18n.locale === 'bn') {
          return examType !== undefined ? examType.text_bn : ''
      } else {
          return examType !== undefined ? examType.text_en : ''
      }
    },
    getTrainingCategory (id) {
      const examType = this.trainingCategoryList.find(item => item.value === parseInt(id))
      if (this.$i18n.locale === 'bn') {
          return examType !== undefined ? examType.text_bn : ''
      } else {
          return examType !== undefined ? examType.text_en : ''
      }
    },
    getTrainingType (id) {
      const examType = this.trainingTypeList.find(item => item.value === parseInt(id))
      if (this.$i18n.locale === 'bn') {
          return examType !== undefined ? examType.text_bn : ''
      } else {
          return examType !== undefined ? examType.text_en : ''
      }
    },
    getOrgData (id) {
      const examType = this.orgList.find(item => item.value === parseInt(id))
      if (this.$i18n.locale === 'bn') {
          return examType !== undefined ? examType.text_bn : ''
      } else {
          return examType !== undefined ? examType.text_en : ''
      }
    },
    getFiscalYear (id) {
      const examType = this.fiscalYearList.find(item => item.value === parseInt(id))
      if (this.$i18n.locale === 'bn') {
          return examType !== undefined ? examType.text_bn : ''
      } else {
          return examType !== undefined ? examType.text_en : ''
      }
    },
    examTypeName (id) {
      const examType = this.examTypeList.find(item => item.value === parseInt(id))
      if (this.$i18n.locale === 'bn') {
          return examType !== undefined ? examType.text_bn : ''
      } else {
          return examType !== undefined ? examType.text_en : ''
      }
    },
    getBatchNo (id) {
      const batchData = this.allBatchList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
          return batchData !== undefined ? batchData.text_bn : ''
      } else {
          return batchData !== undefined ? batchData.text_en : ''
      }
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getTotalMarks () {
      let totalMarks = 0
      let totalObtainedMarks = 0
      this.formData.course_evaluation_answers.filter(answer => {
        totalMarks += answer.evaluation_question.marks
        totalObtainedMarks += answer.marks
      })
      this.totalMarks = totalMarks
      this.totalObtainedMarks = totalObtainedMarks
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tim.pre_post_evaluation_result')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.formData, this)
    }
  }
}
</script>
